.glitch-wrapper {
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   /* background-color: #222; */
}

.glitch {
   position: relative;
   font-size: 50px;
   font-weight: 100;
   font-family: 'Montserrat', sans-serif;
   line-height: 1.2;
   color: #fff;
   letter-spacing: 10px;
   z-index: 1;
}

@media (max-width:767px) {
   .glitch {
      font-size: 30px;
      font-weight: 400;
      letter-spacing: 5px;
   }
}


.glitch:before,
.glitch:after {
   display: block;
   content: attr(data-glitch);
   position: absolute;
   top: 0;
   left: 0;
   opacity: 0.8;
}

.glitch:before {
   animation: glitch-color 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
   color: var(--my-tertiary-color);
   z-index: -1;
}

.glitch:after {
   animation: glitch-color .7s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
   color: var(--my-primary-color);
   z-index: -2;
}

@keyframes glitch-color {
   0% {
      transform: translate(0);
   }

   /* 20% {
      transform: translate(-3px, 3px);
   } */

   40% {
      transform: translate(-2px, -2px);
   }

   60% {
      transform: translate(2px, 2px);
   }
/* 
   80% {
      transform: translate(3px, -3px);
   } */

   to {
      transform: translate(0);
   }
}