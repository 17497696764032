:root {
  --my-primary-color: rgb(136, 0, 255);
  --my-secondary-color: rgb(255, 238, 0);
  --my-tertiary-color: rgb(0, 255, 251);
  --my-light-color: rgb(255, 255, 255);
  --my-dark-color: rgb(68, 68, 68);
  --skills-globe-radius: 150;
}

@media (max-width: 767px) {
  :root {
    --skills-globe-radius: 100;
  }
}


html {
    --section-background-color: linear-gradient(
      to bottom left,
      rgba(17, 16, 16, 0.892),
      rgba(12, 8, 24, 0.904)
    );
  
    --image-gradient: linear-gradient(
      to bottom left,
      rgba(17, 16, 16, 0.678),
      rgba(171, 0, 0, 0.718)
    );
  
    --imp-text-color: var(--my-light-color);
  }
  
  .red {
    color: var(--imp-text-color) !important;
  }
  
  button:focus {
    box-shadow: none !important;
  }

  img {
   -moz-user-select: none;
   -webkit-user-drag: none;
   -webkit-user-select: none;
   -ms-user-select: none;
    /* user-drag: none;   */
    user-select: none;
  }

  .highlight {
    font-weight: 200;
    color: var(--my-tertiary-color);
  }
  
  /* --------- */
  /*  Preloader */
  /* --------- */
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #0c0513;
    /* background-image: url(../assets/preloader.svg); */
    background-repeat: no-repeat;
    background-position: center;
  }
  
  #preloader-none {
    opacity: 0;
  }
  
  #no-scroll {
    overflow: hidden;
    height: 100vh;
  }
  
  /* --------- */
  /*Scrollbar   */
  /* --------- */
  
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #194a50;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--my-tertiary-color);
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--my-primary-color);
    border-radius: 10px;
  }
  
  /* --------- */
  /* Navbar Section  */
  /* --------- */
  .sticky {
    background-color: #1a2d2e66 !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 10px 10px 0px rgba(13, 80, 96, 0.171) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    backdrop-filter: blur(5px) !important;
  }
  
  .navbar {
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.3rem 2rem !important;
    font-size: 1.2rem !important;
  }
  
  .navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }
  
  .navbar-toggler span {
    display: block !important;
    background-color: var(--my-tertiary-color) !important;
    height: 2px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0 !important;
  }
  
  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
  }

  .logo {
    height: 2.4rem !important;
    width: auto !important;
  }
  
  @media (max-width: 767px) {
    .navbar {
      padding: 1rem .3rem !important;
      font-size: 1.4rem !important;
      /* background-color: var(--my-dark-color) !important; */
      background-color: #1a2d2e66 !important;
      transition: all 0.3s ease-out 0s !important;
      box-shadow: 0px 10px 10px 0px rgba(13, 80, 96, 0.171) !important;
      -webkit-backdrop-filter: blur(5px) !important;
      backdrop-filter: blur(5px) !important;
    }
    .navbar-nav .nav-item a::after {
      display: none !important;
    }

    .logo {
      height: 2rem !important;
      width: auto !important;
    }
  }
  .navbar-brand {
    color: rgb(250, 250, 250) !important;
  }
  
  
  
  .navbar-nav .nav-link {
    color: white !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  
  .nav-link {
    padding: 0.8rem 1rem !important;
  }
  
  @media (max-width: 767px) {
    .nav-link {
      padding: 0.7rem 1rem !important;
    }
  }
  
  .navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
  }
  
  .navbar-nav .nav-item a {
    font-weight: 300;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
    
  }
  
  .navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 30px;
    margin-top: -28px;
    padding-left: -2px;
    width: 0;
    background: var(--my-tertiary-color);
    /* box-shadow: 0px 3px 4px #02fbff; */
    border-color: var(--my-tertiary-color);
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
  }
  
  .navbar-nav .nav-item a:hover::after {
    width: 100%;
    background: var(--my-primary-color);
    /* border-style: dotted;
    border-radius: 1px;
    border-left: var(--my-secondary-color);
    border-top: var(--my-secondary-color); */
  }
  
  /* --------- */
  /* Home section */
  /* --------- */
  .wave {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 2.1s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } /* The following five values can be played with to make the waving more or less extreme */
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }
  #tsparticles {
    position: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
  }
  
  .home-header {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  @media screen and (max-width: 767px) {

    .home-header {
      position: relative;
    }
    .home-row {
      flex-direction: column;
    }
  }
  
  .home-content {
    position: relative;
    padding: 9rem 0 2rem !important;
    color: whitesmoke;
    z-index: 2;
  }

  .heading {
    font-size: 2.4em !important;
    padding-left: 50px !important;
    opacity: .5;
  }
  
  .heading-name {
    font-size: 2.5em !important;
    padding-left: 50px !important;
  }
  
  .main-name {
    color: var(--my-primary-color);
  }
  
  .Typewriter__wrapper {
    font-size: 2rem !important;
    color: var(--my-tertiary-color) !important;
    font-weight: 100 !important;
    font-family: 'Montserrat', sans-serif;
  }
  .Typewriter__cursor {
    font-size: 2em !important;
    font-weight: 100 !important;
    color: var(--my-tertiary-color) !important;
  }
  
  @media screen and (max-width: 767px) {
    .Typewriter__wrapper {
      font-size: 1.4em !important;
      font-weight: 300 !important;
      /* position: absolute !important; */
    }
    .Typewriter__cursor {
      display: none !important;
    }
  }

  .myAvtar {
    justify-content: center !important;
    /* padding-top: 9em !important; */
  }
  
  @media (max-width: 767px) {
    .myAvtar {
      padding-top: 2em !important;
      padding-bottom: 2em !important;
    }
  }
  
  .home-about-section {
    color: white;
    position: relative;
    /* padding-bottom: 70px !important; */
    padding-top: 70px !important;
  }
  
  .home-about-description {
    color: white !important;
    /* padding-top: 100px !important; */
    padding-bottom: 20px !important;
    text-align: center;
  }
  
  .home-about-body {
    padding-top: 50px;
    font-size: 1.2em !important;
    text-align: justify;
  }

  @media (max-width: 767px) {
    .home-about-body {
      font-size: 1rem !important;
      text-align: justify;
    }
  }
  
  .home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
  }
  
  .home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
  }
  
  .home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2em !important;
    line-height: 2em !important;
    background: rgba(255, 255, 255, 0.101) !important;
    border-radius: 50% !important;
    transition: 0.5s !important;
  }
  
  .home-social-icons::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* background: var(--my-tertiary-color); */
    transition: 0.2s;
    transform: scale(0.9);
    z-index: -1;
  }
  
  .home-social-icons:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px var(--my-tertiary-color)
  }
  
  .home-social-icons:hover {
    color: var(--my-tertiary-color);
    box-shadow: 0 0 5px var(--my-tertiary-color);
    text-shadow: 0 0 2px var(--my-tertiary-color);
  }
  
  .social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
    color: rgb(152, 65, 65);
  }

  .social-icons i{
    color: var(--my-tertiary-color);
  }
  
  .icon-colour {
    color: #700c86 !important;
  }

  /* --------- */
  /* About */
  /* --------- */
  .about {
    color: white;
  }

  .about-content {
    position: relative;
    padding: 9rem 0 2rem !important;
    color: whitesmoke;
    z-index: 2;
  }

  .about-description {
    color: white !important;
    /* padding-top: 100px !important; */
    padding-bottom: 20px !important;
    text-align: center;
  }
  

  /* --------- */
  /* Project */
  /* --------- */
  .project {
    color: white;
  }

  .project-content {
    position: relative;
    padding: 9rem 0 2rem !important;
    color: whitesmoke;
    z-index: 2;
  }

  .project-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
  }

  .project-card-view {
    box-shadow: 0 4px 4px 3px rgba(0, 255, 251, 0.5) !important;
    color: white !important;
    background-color: transparent !important;
    /* background-color: rgba(0, 255, 251, 0.05) !important; */
    opacity: 1 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
    -webkit-backdrop-filter: blur(5px) !important;
    backdrop-filter: blur(4px) !important;
  }

  .project-card-view:hover {
    transform: scale(1.04) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px rgba(0, 255, 251, 0.7) !important;
  }

  @media (max-width: 767px) {
    .project-card-view {
      font-size: .8rem;
     }
  }


  /* --------- */
  /* Footer */
  /* --------- */
  .footer {
    background-color: rgba(37, 37, 37, 0.378);
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important ;
    position: relative;
    bottom: 0;
  }
  .footer-copyright {
    text-align: center !important;
  }
  
  .footer-body {
    z-index: 1;
    text-align: center !important;
  }

  .footer h6 {
    font-size: 1rem;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.5) !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
  
  @media (max-width: 767px) {
    .footer-copyright {
      text-align: center !important;
    }
  
    .footer-body {
      text-align: center !important;
    }
    .footer h6 {
      font-size: .7rem;
      font-weight: 200;
  }
}
  

  .footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
  }
  
  .blockquote-footer {
    color: #a588c0 !important;
  }
  /* --------- */
  /* Projects */
  /* --------- */
  /* .project-section {
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
  }
  
  .project-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
  }
  
  .project-card-view {
    box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
    color: white !important;
    background-color: transparent !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
  }
  .project-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
  } */
  
  .blog-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
  }
  
  .blog-card-view {
    background-color: transparent !important;
    box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
    color: white !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
  }
  
  .blog-link {
    color: white !important;
    text-decoration: none !important;
  }
  
  .blog-link:hover {
    cursor: pointer !important;
  }
  
  .blog-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
  }
  
  .card-img-top {
    padding: 20px !important;
    opacity: 0.8 !important;
    border-radius: 10px !important;
  }
  
  .blog-img {
    padding: 0px !important;
    opacity: 0.8 !important;
    border-radius: 0px !important;
  }
  
  .btn-primary {
    color: #fff !important;
    background-color: #623686 !important;
    border-color: #623686 !important;
  }
  
  .btn-primary:hover {
    color: #fff !important;
    background-color: #6d20c5d7 !important;
    border-color: #6d20c5d7 !important;
  }
  .btn:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .project-heading {
    color: white !important;
    font-size: 2.3em !important;
    font-weight: 500 !important;
    padding-top: 10px !important;
  }
  
  /* --------- */
  /* About */
  /* --------- */
  
  .about-section {
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
  }

  .about-tagline {
    color: white !important;
    padding-top: 20px !important;
    text-align: center;
  }

  .about-body {
    padding-top: 20px;
    font-size: 1.2rem !important;
    text-align: justify;
  }

  @media (max-width: 767px) {
    .about-body {
      font-size: 1rem !important;
      padding-left: 25px !important;
      padding-right: 25px !important;
      text-align: justify;
    }
  }
  
  .tech-icons {
    font-size: 2em !important;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(0, 255, 251, 0.7) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: table !important;
    box-shadow: 3px 5px 4px 3px rgba(0, 255, 251, 0.1) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
  }
  
  @media (max-width: 767px) {
    .tech-icons {
      font-size: 1em !important;
      margin: 20px !important;
    }
  }
  
  .tech-icons:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(0, 255, 251, 1) !important;
  }
  .tech-icon-images {
    padding: 20px !important;
    line-height: 1.6 !important;
  }
  
  .quote-card-view {
    border: none !important;
    color: white !important;
    background-color: transparent !important;
  }
  
  .about-activity {
    list-style: none !important;
    text-align: left !important;
    padding-left: 1px !important;
  }
  
  @media (max-width: 767px) {
    .about-img {
      padding-top: 0 !important;
    }
  }
  /* --------- */
  /* Resume */
  /* --------- */
  
  .react-pdf__Page__textContent  {
    display: none;
  }

  .resume-section {
    position: relative !important;
    padding-top: 110px !important;
    padding-bottom: 30px !important;
    /* background-image: var(--section-background-color) !important; */
    color: white !important;
  }
  
  .resume {
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;
  }
  
  .resume-left {
    padding-right: 80px !important;
  }

  .resume-section-download i{
    content: "";
    position: relative;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
    color: white;
    border-color: var(--my-tertiary-color);
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    padding: 10px;
    font-size: .8rem;
  }

  .resume-section-download i:hover::after{
    content: "";
    position: relative;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
    color: white;
    padding: 10px;
  }

  .resume-section-download a::after {
    content: "";
    max-width: 200px;
    position: relative;
    display: block;
    height: 33px;
    margin-top: -33px;
    padding-left: -2px;
    width: 0;
    background: var(--my-tertiary-color);
    /* box-shadow: 0px 3px 4px #02fbff; */
    border-color: var(--my-tertiary-color);
    border-radius: 5px;
    left: 39px;
    z-index: -2;
    transition: all 0.3s ease-out 0s;
  }
  
  .resume-section-download a:hover::after {
    width: 66%;
    background: var(--my-primary-color);
    color: white;
    text-decoration: none;
  }
  
  .resume-right {
    padding-left: 80px !important;
  }
  
  @media (max-width: 767px) {
    .resume-left {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  
    .resume-right {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }
  .resume .resume-title {
    font-size: 2em;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .resume .resume-item {
    padding: 0 0 10px 25px;
    margin-top: -2px;
    border-left: 2px solid var(--my-tertiary-color);
    position: relative;
  }
  
  .resume .resume-item .resume-title {
    line-height: 18px;
    font-size: 0.9em;
    background: #5234795d;
    padding: 8px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .resume .resume-item ul {
    padding-left: 20px;
    text-align: justify;
  }
  
  .resume .resume-item ul li {
    padding-bottom: 10px;
    list-style: none;
  }
  
  .resume .resume-item:last-child {
    padding-bottom: 0;
  }
  
  .resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid #8a49a8;
  }
  
  .like-item {
    padding-top: 10px !important;
    font-size: 1.1em !important;
    font-family: sans-serif !important;
  }
  
  .like-btn {
    background-color: #934cce5e !important;
    border-color: #934cce5e !important;
    padding: 0.25rem 0.98rem !important;
    border-radius: 5px !important;
    line-height: 1.4 !important;
    transition: 0.3s ease !important;
  }
  
  .like-btn:hover {
    transform: translateY(-2px) !important;
    background-color: #a24dd386 !important;
    border-color: #a24dd386 !important;
  }
  
  .animate-like {
    animation-name: likeAnimation;
    animation-fill-mode: forwards;
    animation-duration: 0.85s;
  }
  @keyframes likeAnimation {
    0% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .fork-btn {
    font-size: 1.1em !important;
    padding-top: 10px !important;
  }
  
  .fork-btn-inner {
    line-height: 1.4em !important;
    background-color: var(--my-primary-color) !important;

    padding: 0.25rem 1.1rem !important;
    vertical-align: middle !important;
    text-align: center !important;
  }
  
  .fork-btn-inner:hover {
    transform: translateY(-2px) !important;
    background-color: var(--my-tertiary-color) !important;
    border-color: var(--my-primary-color)!important;
  }
  .fork-btn-inner::after {
    display: none !important;
  }


/* Spinner */
.spinner-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-color: #0c0513;

}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 44.8px;
  height: 44.8px;
  animation: spinner-y0fdc1 2s infinite ease;
  transform-style: preserve-3d;
  z-index: 999999;
  /* background-color: #0c0513; */
}

.spinner > div {
  background-color: rgba(71, 246, 255, 0.2);
  height: 100%;
  position: absolute;
  width: 100%;
  border: 2.2px solid var(--my-tertiary-color)
}

.spinner div:nth-of-type(1) {
  transform: translateZ(-22.4px) rotateY(180deg);
}

.spinner div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right;
}

.spinner div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left;
}

.spinner div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center;
}

.spinner div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center;
}

.spinner div:nth-of-type(6) {
  transform: translateZ(22.4px);
}

@keyframes spinner-y0fdc1 {
  0% {
     transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
  }

  50% {
     transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
  }

  100% {
     transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
  }
}

.spinner-none {
  opacity: 0;
}

@media (max-width: 767px) {
  .githubstat {
    max-width: 300px;
  }
  .leetcodestat{
    max-width: 300px;
    padding-bottom: 20px;
  }
}

.tagcloud {
  width: 400px;
  height: 400px;
  margin-top: 50px;
}

@media (max-width: 993px) {
  .tagcloud {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 767px) {
  .tagcloud {
    /* width: 150px;
    height: 150px; */
    margin-bottom: 100px;
    margin-top: 0px;
    margin-left: -50px;
  }
}

.contact-content {
  position: relative;
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  z-index: 2;
}

.contact-description {
  color: white !important;
  padding: 30px;
  text-align: center;
  margin-bottom: 10px;
}

.contact-form {
  padding-top: 70px !important;
  padding: 30px;
  margin-bottom: 10px;
}

.contact-form i{
  color: white;
}

.contact-form i:hover{
  color: var(--my-tertiary-color);
  transition: 0.2s ease-in-out;
}


@media (max-width: 767px) {
  .contact-form {
    padding-top: 20px !important;
    padding: 30px;
    margin-bottom: 20px;
  }
  .contact-description {
    color: white !important;
    padding: 30px;
    padding-bottom: 0px;
    text-align: center;
    margin-bottom: 10px;
  } 
}